import styled from "styled-components";

export const ContentWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  width: calc(100% - 40px);
  max-width: calc(720px - 40px);
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 54px;

  padding: 0px 0px;
  z-index: 1000;

  background: linear-gradient(0deg, #f4f2e8, #f4f2e8),
    linear-gradient(
      135.21deg,
      #f9e8a0 3.84%,
      #e6745a 33.99%,
      #a6708e 64.9%,
      #8490a8 93.84%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: double 3px transparent;

  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Img = styled.img`
  position: absolute;
  width: 22px;
  height: 22px;
  border: double 2px transparent;
`;

export const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  position: relative;
  background: ${(props) =>
    props.active
      ? "linear-gradient(135.21deg, #f9e8a0 3.84%, #e6745a 33.99%, #a6708e 64.9%,#8490a8 93.84%)"
      : "#4F5D79"};
`;
