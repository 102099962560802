/**
 * Styles used throughout the app
 *
 *
 *  Text
 *    optional props:
 *        fontSize [string] [default "12px"]
 *        lineHeight [string] [default "15px"]
 *        fontWeight [string] [default "400"]
 *        color [string] [default "#4f5d79"]
 *        center [boolean] [default "left"]
 *        mobileFont [string] font size on viewports < 480px
 *
 *  Underline -- the Text styled component but underlined and with pointer cursor
 *
 *  AllContentWrapper -- used in App.js to wrap app's content (everything excluding navbar)
 *
 *  LineLimiter -- limit the number of lines
 */

import styled from "styled-components";
import "@fontsource/montserrat";
import "@fontsource/montserrat/700.css"; // Weight 700.
import "@fontsource/bungee";

export const Text = styled.div`
  font-family: ${(props) => props.fontFamily || "Montserrat"};
  font-size: ${(props) => props.fontSize || "12px"};
  line-height: ${(props) => props.lineHeight || "15px"};
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  color: ${(props) => props.color || "#4f5d79"};
  text-align: ${(props) => (props.center ? "center" : "left")};

  ${(props) =>
    props.mobileFont &&
    `@media screen and (max-width: 480px) {font-size: ${props.mobileFont}}`}
`;

export const Underline = styled(Text)`
  text-decoration: underline;
  text-decoration-color: #4f5d79;
  cursor: pointer;
`;

export const AllContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const LineLimiter = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.number || "1"};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
