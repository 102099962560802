/**
 * Loading
 *
 * Placeholder for when content is loading
 *
 * Usage: App.js
 */
import React from "react";
import { Loading, LoadingContainer } from "./style";

export default function index() {
  return (
    <LoadingContainer>
      <Loading>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Loading>
    </LoadingContainer>
  );
}
