/**
 *
 * Auth Context
 *
 * Provides current user object to entire app
 *
 * Value:
 *      - signedIn (boolean) - indicates whether user is signed in or not
 *      - currentUser (string) - current user's userID
 *      - email (string) - current users's email
 *      - token (string) - token that you can use to make requests
 *      - pending (boolean) - whether or not currentUser, token, and signedIn have been resolved
 *
 * Used in App.js. See Header.js for example usage.
 */

import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import { auth } from "../services/firebase-service";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(false);
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [isGuest, setIsGuest] = useState();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const fetchUser = () => {
      auth().onAuthStateChanged(async (user) => {
        if (user) {
          setCurrentUser(user.uid);
          setEmail(user.email);
          setIsGuest(user.isAnonymous);
          setToken(await user.getIdToken());
        } else {
          setCurrentUser();
          setEmail();
          setIsGuest();
          setToken(null);
        }
        setPending(false);
      });
    };
    fetchUser();
  }, []);

  const value = useMemo(
    () => ({
      currentUser,
      signedIn: currentUser !== undefined,
      token,
      pending,
      email,
      isGuest,
    }),
    [currentUser, token, pending, email, isGuest]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
  }
  if (context.pending) return { pending: context.pending };

  return context;
}
