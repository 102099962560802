/**
 * Navbar Component
 *
 * Toggles between home, leaderboard, and profile
 *
 * Usage: App.js
 */

import React, { useState, useEffect, useCallback } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { useUserContext } from "../../contexts/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import { ContentWrapper, IconWrapper, Background, Img } from "./style";

import homeImg from "../../assets/images/navbar/home.svg";
import homeActiveImg from "../../assets/images/navbar/homeActive.svg";
import leaderboardImg from "../../assets/images/navbar/leaderboard.svg";
import leaderboardActiveImg from "../../assets/images/navbar/leaderboardActive.svg";
import profileImg from "../../assets/images/navbar/profile.svg";
import profileActiveImg from "../../assets/images/navbar/profileActive.svg";
import { avatarList } from "../../assets/images/avatars";

function Navbar() {
  const location = useLocation();
  const getPage = useCallback(() => {
    if (location.pathname === "/") return "home";
    if (location.pathname === "/leaderboard") return "leaderboard";
    if (location.pathname.includes("profile")) return "profile";
    return null;
  }, [location.pathname]);
  const [page, setPage] = useState(getPage() || "home");
  const { currentUser, isGuest } = useAuthContext();
  const { avatar } = useUserContext();

  useEffect(() => {
    const newPage = getPage();
    if (newPage) setPage(newPage);
  }, [location.pathname, getPage]);

  let navigate = useNavigate();

  return (
    <ContentWrapper>
      <IconWrapper style={{ zIndex: "100" }}>
        <IconToggler
          active={page === "home"}
          inactiveImg={homeImg}
          activeImg={homeActiveImg}
          onClick={() => {
            navigate("/", { replace: true });
          }}
        />
        <IconToggler
          active={page === "leaderboard"}
          inactiveImg={leaderboardImg}
          activeImg={leaderboardActiveImg}
          onClick={() => {
            navigate("/leaderboard", { replace: true });
          }}
        />
        <IconToggler
          active={page === "profile"}
          inactiveImg={avatarList[avatar] || profileImg}
          activeImg={avatarList[avatar] || profileActiveImg}
          onClick={() => {
            navigate(`/profile`, { replace: true });
          }}
          profile
          signedIn={currentUser && !isGuest}
        />
      </IconWrapper>
    </ContentWrapper>
  );
}

const IconToggler = ({
  active,
  inactiveImg,
  activeImg,
  onClick,
  profile,
  signedIn,
}) => {
  const hide = { display: "none" };

  if (profile && !activeImg) {
    return (
      <img
        src={inactiveImg}
        width="22px"
        height="22px"
        alt="inactive navbar icon"
        style={active ? { ...hide } : {}}
      />
    );
  }

  return (
    <div onClick={onClick}>
      {!profile || !signedIn ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={activeImg}
            width="22px"
            height="22px"
            alt="active navbar icon"
            style={!active ? { ...hide } : {}}
          />
          <img
            src={inactiveImg}
            width="22px"
            height="22px"
            alt="inactive navbar icon"
            style={active ? { ...hide } : {}}
          />
        </div>
      ) : (
        <Background active={active}>
          <Img
            src={activeImg}
            alt="active navbar icon"
            style={!active ? { ...hide } : {}}
          />
          <Img
            src={inactiveImg}
            width="22px"
            height="22px"
            alt="inactive navbar icon"
            style={active ? { ...hide } : {}}
          />
        </Background>
      )}
    </div>
  );
};

export default Navbar;
