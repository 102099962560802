/**
 * User Context
 *
 * Provides info about user to entire app
 * Value:
 *     - username
 *     - avatar
 *     - date_created
 *     - num_violations
 *     - karma
 *
 */

import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useAuthContext } from "./AuthContext";
import axios from "axios";

const UserContext = createContext({});

export function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useState(null);
  const { currentUser, pending, email, isGuest, token } = useAuthContext();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (pending) return; // wait for auth context to resolve

    const getInfo = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/profile/info/${currentUser}`)
        .then((res) => {
          setUserInfo(res.data);
        })
        .catch((err) => {
          // Could not fetch profile. This could be a guest account, so let's
          // create a new guest account in the backend.
          if (isGuest) {
            axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/profile/signup/guest`,
              {},
              { headers: { Authorization: `Bearer ${token}` } }
            );
          }
        });
    };

    currentUser ? getInfo() : setUserInfo(null);
  }, [currentUser, pending, refresh, isGuest, token]);

  const value = useMemo(
    () => ({ ...userInfo, email, refresh: [refresh, setRefresh] }),
    [userInfo, email, refresh]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("UserContext must be used within a UserContextProvider");
  }
  return context;
}
