/**
 *
 * Application Data Context
 *
 * Provides application data to entire app
 * Can be used to cache application data (eg. cache fetching all admins so we don't have to fetch it every time)
 *
 * Currently contains:
 *      - homeRestrooms [array] - all restrooms pulled from /home/restrooms
 *      - leaderboardRestrooms [array] - all restrooms pulled from /leaderboard/all
 *      - homeSearch [string] - what the user has currently typed into the search bar
 *                        (so they don't have to retype their query if they go back)
 *      - setHomeSearch [function] - setState to modify homeSearch
 *      - refreshHome [function] - call to refetch /home/restrooms (used when admin
 *                        dashboard makes change)
 *      - images [dict<id, dict<small_url, url>>] - contains restroom images
 *
 *
 */

import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

export const DataContext = React.createContext({});

export function DataProvider({ children }) {
  const location = useLocation();
  const [homeRestrooms, setHomeRestrooms] = useState([]);
  const [leaderboardRestrooms, setLeaderboardRestrooms] = useState([]);
  const [homeLoaded, setHomeLoaded] = useState(false);
  const [leaderboardLoaded, setLeaderboardLoaded] = useState(false);
  const [images, setImages] = useState({}); // {id: {url, small_url}}

  // home restrooms
  useEffect(() => {
    const url = location.pathname;
    if ((url === "/" || url === "/admin") && !homeLoaded) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/home/restrooms`)
        .then((res) => {
          setHomeLoaded(true);
          return setHomeRestrooms(res.data);
        });
    }
  }, [location.pathname, homeLoaded]);

  // leaderboard restrooms
  useEffect(() => {
    const url = location.pathname;
    if (url === "/leaderboard" && !leaderboardLoaded) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/leaderboard/all`)
        .then((res) => {
          setLeaderboardLoaded(true);
          return setLeaderboardRestrooms(res.data);
        });
    }
  }, [location.pathname, leaderboardLoaded]);

  // get images
  useEffect(() => {
    const url = location.pathname;
    if (url === "/leaderboard" || url === "/" || url.includes("profile")) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/restrooms/images/`)
        .then((response) => {
          var imageObj = {};
          response.data.forEach((item) => {
            if (item) {
              imageObj[item._id] = {
                small_url: item.small_url,
                url: item.url,
              };
            }
          });
          setImages(imageObj);
        });
    }
  }, [location.pathname]);

  const value = useMemo(
    () => ({
      homeRestrooms,
      leaderboardRestrooms,
      refreshHome: () => setHomeLoaded(false),
      images,
    }),
    [homeRestrooms, leaderboardRestrooms, images]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
