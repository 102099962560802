import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuthContext, AuthProvider } from "./contexts/AuthContext";
import { useUserContext, UserProvider } from "./contexts/UserContext";
import React, { Suspense, lazy } from "react";
import Navbar from "./components/Navbar";
import Loading from "./components/Loading";
import { AllContentWrapper } from "./assets/universal";
import { DataProvider } from "./contexts/DataContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/montserrat";

// lazy loading to minimize bundle size
const LeaderboardPage = lazy(() => import("./Leaderboard/LeaderboardPage"));
const RestroomPage = lazy(() => import("./Restroom/RestroomPage"));
const ProfilePage = lazy(() => import("./Profile/ProfilePage"));
const HomePage = lazy(() => import("./Home/HomePage"));
const Login = lazy(() => import("./User/Login"));
const Signup = lazy(() => import("./User/Signup"));
const NotFound = lazy(() => import("./User/NotFound"));
const AdminPage = lazy(() => import("./Admin/AdminPage"));
const Redirect = lazy(() => import("./Restroom/Redirect"));

function AppRouter() {
  const { signedIn, pending } = useAuthContext();
  const { admin } = useUserContext();

  return (
    <Router>
      <DataProvider>
        <Navbar />
        <AllContentWrapper>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* Not logged in */}
              <Route
                path="/login"
                element={
                  pending ? <Loading /> : !signedIn ? <Login /> : <NotFound />
                }
              />
              <Route
                path="/signup"
                element={
                  pending ? <Loading /> : !signedIn ? <Signup /> : <NotFound />
                }
              />

              {
                <Route
                  path="/admin"
                  element={
                    pending ? <Loading /> : admin ? <AdminPage /> : <NotFound />
                  }
                />
              }

              <Route path="/" element={<HomePage />} />
              <Route path="/leaderboard" element={<LeaderboardPage />} />
              <Route path="/restroom/:restroom_id">
                <Route path=":section" element={<RestroomPage />} />
                <Route path="" element={<RestroomPage />} />
              </Route>
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/qr/:qr_id" element={<Redirect />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/404" element={<NotFound />} />
            </Routes>
          </Suspense>
          {/* This is the div that we target when we make a modal. We only assume we will have one modal open at a time, so it's okay to have it at the top level here. */}
          <div id="modal"></div>
        </AllContentWrapper>
      </DataProvider>
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <AppRouter />
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
