import avatar0 from "./1.png";
import avatar1 from "./2.png";
import avatar2 from "./3.png";
import avatar3 from "./4.png";
import avatar4 from "./5.png";
import avatar5 from "./6.png";
import avatar6 from "./7.png"; // guest account avatar

export const avatarList = [
  avatar0,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
];
