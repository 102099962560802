import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";

const firebaseConfig = JSON.parse(
  process.env.REACT_APP_FIREBASE_CREDENTIALS_JSON
);

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth;

export const analytics = firebase.analytics;

export default firebase;
